<template class="page-box">
  <div>
    <el-row>
      <el-col :span="24">
        <div class="beijing">
          <img :src="xinxi.banner[1].image" alt="" />
        </div>
      </el-col>
    </el-row>
    <div class="zt">
      <div class="title">
        <img src="@/assets/biaoti.png" alt="" />
        <div class="title_text">
          <span class="msyhbd">运 营 中 心 布 局</span>
        </div>
      </div>
      <div class="content">
        <el-row>
          <el-col :span="6">
            <div class="blockText">
              <div
                class="littleBox"
                v-for="(item, index) in leftData"
                :key="index"
              >
                <img class="bgImg" src="@/assets/zu134.png" alt="" />
                <div class="textTitle">
                  <span>{{ item.title }}</span>
                </div>
                <div class="textContent">
                  <p v-html="item.content"></p>
                </div>
              </div>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="blockMap">
              <div class="number">
                <span style="color: #4df5b0"
                  >平台应用<span style="color: #4df5b0"
                    >人次 （实时）</span
                  ></span
                >
                <div>
                  <span v-for="(item, index) in midData" :key="index">
                    <span
                      v-if="
                        (midData.length - index - 1) % 3 != 0 ||
                        midData.length == index + 1
                      "
                      ><span class="num-box ManopMai-Bold">{{ item }}</span
                      >{{ " " }}</span
                    >
                    <span v-else
                      ><span class="num-box ManopMai-Bold">{{ item }}</span
                      >{{ ' ，' }}</span
                    >
                  </span>
                </div>
              </div>
              <div id="map"></div>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="blockLine">
              <div class="littleBox">
                <img class="bgImg" src="@/assets/zu134.png" alt="" />
                <div class="textTitle">
                  <span>{{ rightData[0] ? rightData[0].title : "" }}</span>
                </div>
                <div class="imgContent">
                  <div id="yyzxywqxt"></div>
                </div>
              </div>
              <div class="littleBox">
                <img class="bgImg" src="@/assets/zu134.png" alt="" />
                <div class="textTitle">
                  <span>{{ rightData[1] ? rightData[1].title : "" }}</span>
                </div>
                <div class="imgContent">
                  <div id="ptyyrsqxt"></div>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import "@/assets/js/china.js";
export default {
  data() {
    return {
      leftData: [],
      midData: [],
      rightData: [],
      xinxi: {
        banner:[
          {"image":"https://qmdata.iueang.com//uploads/20230315/2008e9bae751332c92a9f6b9d7919ff6.jpg"},
          {"image":"https://qmdata.iueang.com//uploads/20230315/2008e9bae751332c92a9f6b9d7919ff6.jpg"},
          {"image":"https://qmdata.iueang.com//uploads/20230315/2008e9bae751332c92a9f6b9d7919ff6.jpg"},
          {"image":"https://qmdata.iueang.com//uploads/20230315/2008e9bae751332c92a9f6b9d7919ff6.jpg"},
        ]
      },
    };
  },
  mounted() {
    this.initLineAjax();
    this.initMap();
  },
  methods: {

    initLineAjax() {
      this.$axios.post("/api/index/index")
        .then((res) => {
          this.xinxi = res.data.data;
        })
        .catch((error) => console.log(error));
      this.$axios.post("api/index/operation").then((res) => {
        this.leftData = res.data.data.filter((item) => {
          return item.title == "运营中心布局" || item.title == "业务分布";
        });
        this.rightData = res.data.data.filter((item) => {
          return item.content.length > 0 && typeof item.content != "string";
        });
        let number = res.data.data.filter((item) => {
          return item.title == "平台应用人次";
        });
        if (number[0]["content"]) {
          if (typeof this.midData == "string") {
            this.midData = number[0]["content"];
          } else {
            this.midData = number[0]["content"] + "";
          }
        } else {
          this.midData = "0";
        }
        this.$nextTick(() => {
          this.initLine1(this.rightData[0].content);
          this.initLine2(this.rightData[1].content);
        });
      });
    },
    initLine1(data) {
      let echarts = require("echarts");
      let myChart = echarts.init(document.getElementById("yyzxywqxt"));
      let option = {
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: ["06:00", "08:00", "11:00", "15:00", "18:00", "22:40"],
          axisLabel: {
            textStyle: {
              color: "#5cb2fd",
              fontFamily:'arial',
            },
          },
        },
        yAxis: {
          type: "value",
          splitLine: {
            show: false,
          },
          axisLabel: {
            textStyle: {
              color: "#fff",
              fontFamily:'arial',
            },
          },
        },
        series: [
          {
            data: data,
            type: "line",
            areaStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(4,225,246,1)",
                  },
                  {
                    offset: 1,
                    color: "rgba(4,225,246,0)",
                  },
                ],
                global: false,
              },
            },
            lineStyle: {
              color: "rgba(4,225,246,1)",
            },
            smooth: true,
            symbol: 'none',
          },
        ],
      };
      option && myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
    initLine2(data) {
      let echarts = require("echarts");
      let myChart = echarts.init(document.getElementById("ptyyrsqxt"));
      let option = {
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: ["06:00", "08:00", "11:00", "15:00", "18:00", "22:40"],
          axisLabel: {
            textStyle: {
              color: "#5cb2fd",
              fontFamily:'arial',
            },
          },
        },
        yAxis: {
          type: "value",
          splitLine: {
            show: false,
          },
          axisLabel: {
            textStyle: {
              color: "#fff",
              fontFamily:'arial',
            },
          },
        },
        series: [
          {
            data: data,
            type: "line",
            areaStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(255,248,0,1)",
                  },
                  {
                    offset: 1,
                    color: "rgba(255,248,0,0)",
                  },
                ],
                global: false,
              },
            },
            lineStyle: {
              color: "rgba(255,248,0,1)",
            },
            smooth: true,
            symbol: 'none',
          },
        ],
      };
      option && myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
    initMap() {
      let mapName = "china";
      let echarts = require("echarts");
      let myChart = echarts.init(document.getElementById("map"));
      let data = [
        {
          name: "山东",
          value: 4000000000,
        },
        {
          name: "吉林",
          value: 300000000,
        },

        {
          name: "河南",
          value: 300000000,
        },
        {
          name: "湖北",
          value: 300000000,
        },
        {
          name: "浙江",
          value: 300000000,
        },
        {
          name: "江苏",
          value: 300000000,
        },
      ];
      let coordinate = [
      {
          name: "青岛",
          value: [120.19,36.04],
        },
        {
          name: "长春",
          value: [125.18,43.55],
        },

        {
          name: "郑州",
          value: [113.42,34.44],
        },
        {
          name: "武汉",
          value: [114.20,30.37],
        },
        {
          name: "温州",
          value: [120.38,28.00],
        },
        {
          name: "苏州",
          value: [120.39,31.20],
        },
      ]
      let dataVal = coordinate.map((v,i)=>{
        return{
          name: v.name,
          value: v.value.concat(data[i].value),
        }
      })
      let option = {
        visualMap: {
          show: true,
          left: "10%",
          top: "bottom",
          type: "piecewise",
          splitNumber: 3,
          pieces: [
            {
              lt: 100000000,
              color: "rgb(14,45,166)",
              label: "1亿以下",
            },
            {
              gte: 100000000,
              lt: 400000000,
              label: "1亿-4亿",
              color: "rgb(42,103,233)",
            },
            { gte: 400000000, label: "4亿以上", color: "rgb(79,202,253)" },
          ],
          textStyle: {
            color: "#fff",
          },
          calculable: true,
        },
        geo: {
          show: true,
          map: mapName,
          zoom: 1.2,
          aspectScale: 0.9,
          label: {
            normal: {
              show: false,
            },
            emphasis: {
              show: false,
            },
          },
          roam: false,
          itemStyle: {
            normal: {
              areaColor: "rgb(6,46,171)",
              borderColor: "rgb(0,144,191)",
            },
          },
        },
        series: [
          {
            name: "运营中心",
            type: "scatter",
            zoom: 1.2,
            aspectScale:0.9,
            coordinateSystem: "geo",
            data: dataVal,
            label: {
              normal: {
                formatter: "{b}",
                position: "right",
                show: true,
              },
              emphasis: {
                show: true,
              },
            },
            symbol:'image://'+ require('@/assets/xuanzhong.png'),
            emphasis: {
              scale: false,
            },
            z: 1,
            symbolSize: [30, 30]
          },
          {
            name: "总部",
            type: "scatter",
            zoom: 1.2,
            aspectScale:0.9,
            coordinateSystem: "geo",
            data: [{...dataVal[0]}],
            label: {
              normal: {
                show: false,
              },
              emphasis: {
                show: false,
              },
            },
            symbol:'image://'+ require('@/assets/zongbu.png'),
            emphasis: {
              scale: false,
            },
            z: 1,
            symbolSize: [100, 150]
          },
          {
            type: "map",
            map: mapName,
            geoIndex: 0,
            zoom: 1.2,
            aspectScale:0.9,
            showLegendSymbol:false,
            data: data,
            label: {
              normal: {
                show: false,
              },
              emphasis: {
                show: false,
              },
            },
          },
        ],
      };
      myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  color: #fff;
}
.el-row {
  margin-bottom: 0 !important;
  .beijing {
    width: 100%;
    height: 22vh;
    img {
      width: 100%;
      height: 100%;
    }
  }
}

.zt {
  height: 78vh;
  padding: 0 1%;
  background-image: url("@/assets/background.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  flex-direction: column;
  .title {
    margin: 1vh auto 0;
    width: 90%;
    height: 6vh;
    position: relative;
    img {
      width: 100%;
      height: 100%;
    }
    .title_text {
      position: absolute;
      font-weight: 700;
      text-align: center;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 0;
      font-size: 28px;
    }
  }
  .content {
    width: 100%;
    height: 72vh;
    padding: 1%;
    box-sizing: border-box;
    .el-row {
      height: 100%;
      .el-col {
        height: 100%;
        .bgImg {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
        }
        .littleBox {
          position: relative;
          .textTitle {
            width: 100%;
            text-align: center;
            display: flex;
            height: 14%;
            margin-top: 4%;
            flex-direction: column;
            justify-content: center;
            position: relative;
            z-index: 2;
          }
          .textContent {
            width: 100%;
            height: 75%;
            box-sizing: border-box;
            padding: 0 12%;
            display: flex;
            line-height: 2;
            align-items: center;
            position: relative;
            z-index: 2;
          }
          .imgContent {
            width: 100%;
            height: 75%;
            position: relative;
            z-index: 2;
            display: flex;
            justify-content: center;
            align-items: center;
            div {
              width: 20vw;
              height: 26vh;
            }
          }
        }
        .blockText,
        .blockMap,
        .blockLine {
          height: 100%;
        }
        .blockText,
        .blockLine {
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          align-items: center;
          .littleBox {
            width: 98%;
          }
        }
        .blockText {
          .littleBox {
            height: 45%;
          }
        }
        .blockMap {
          position: relative;
          .number {
            width: 100%;
            height: 20%;
            display: flex;
            justify-content: space-around;
            align-items: center;
            flex-direction: column;
            .num-box {
              font-size: 2.8vw;
              color: #fff;
              line-height: 3.5vw;
              display: inline-block;
              width: 2.5vw;
              height: 3.5vw;
              text-align: center;
              background-size: 2.5vw 3.5vw;
              margin-right: 2px;
              background-color: rgb(2, 87, 229);
              &:last-child {
                margin-right: 0;
              }
            }
          }
          #map {
            position: absolute;
            bottom: 2%;
            top: 20%;
            left: 2%;
            right: 2%;
            width: 46vw;
            height: 50vh;
          }
        }
        .blockLine {
          .littleBox {
            height: 48%;
          }
        }
      }
    }
  }
}
</style>
