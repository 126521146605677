<template  class="page-box">
  <div id="app">
    <el-carousel height="100vh" direction="vertical" :autoplay="false" :interval="5000">
      <el-carousel-item>
        <MenuName />
      </el-carousel-item>
      <el-carousel-item>
        <SecondScreen />
      </el-carousel-item>
      <el-carousel-item>
        <ThirdScreen />
      </el-carousel-item>
      <el-carousel-item>
        <FourScreen />
      </el-carousel-item>
    </el-carousel>
    <!-- <el-container>
      <el-aside>

      </el-aside>
      <el-main>
        <router-view/>
      </el-main>
    </el-container> -->
  </div>
</template>

<script>
import MenuName from "./views/HomeView.vue";
import SecondScreen from "@/views/SecondScreen.vue";
import ThirdScreen from "@/views/ThirdScreen.vue";
import FourScreen from "@/views/FourScreen.vue";
export default {
  components: { MenuName, SecondScreen, ThirdScreen,FourScreen },
  name: "App",
};
</script>
<style>
*{
  font-family: 'msyh';
}
.page-box {
  background-color: #161622;
  height: 100vh;
}

div {
  box-sizing: border-box;
}
.el-carousel__indicators--vertical {
  right: auto;
  left: 0;
}
@font-face {
  font-family: "arial";
  src: url("@/assets/font/arial.ttf");
}
.arial{
  font-family: 'arial';
}
@font-face {
  font-family: "ManopMai-Bold";
  src: url("@/assets/font/ManopMai-Bold.OTF");
}
.ManopMai-Bold{
  font-family: 'ManopMai-Bold';
}
/* 微软雅黑 */
@font-face {
  font-family: "msyh";
  src: url("@/assets/font/msyh.ttc");
}
.msyh{
  font-family: 'msyh';
}
@font-face {
  font-family: "msyhbd";
  src: url("@/assets/font/msyhbd.ttc");
}
.msyhbd{
  font-family: 'msyhbd';
}
@font-face {
  font-family: "msyhl";
  src: url("@/assets/font/msyhl.ttc");
}
.msyhl{
  font-family: 'msyhl';
}
@font-face {
  font-family: "Uni-Sans-Regular";
  src: url("@/assets/font/Uni-Sans-Regular.otf");
}
.Uni-Sans-Regular{
  font-family: 'Uni-Sans-Regular';
}
@font-face {
  font-family: "PIZZO";
  src: url("@/assets/font/pizzo.ttf");
}
.PIZZO{
  font-family: 'PIZZO';
}
</style>
