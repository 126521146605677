import Vue from 'vue'
import ElementUI from 'element-ui';             //全局引入element
import 'element-ui/lib/theme-chalk/index.css';    //全局引入element的样式
import App from './App.vue'
// import VScaleScreen from 'v-scale-screen'
// Vue.use(VScaleScreen)

Vue.config.productionTip = false
import echarts from 'echarts'

Vue.prototype.$echarts = echarts;
import axios from 'axios'
Vue.prototype.$axios = axios
axios.defaults.baseURL = 'https://qmdata.iueang.com/';
// axios.defaults.baseURL = 'http://xxskht.iueang.com/index.php/';
Vue.use(ElementUI);     //全局注入element
new Vue({
  axios,
  render: h => h(App),
}).$mount('#app')
