<template class="page-box">
  <div>
    <el-row>
      <el-col :span="24">
        <div class="beijing">
          <img :src="xinxi.banner[0].image" alt="" />
        </div>
      </el-col>
    </el-row>
    <div class="zt">
      <div class="meige">
        <div class="zuocet1">
          <div
            style="
              display: flex;
              justify-content: space-between;
              width: 95%;
              margin-left: 2.5%;
            "
          >
            <div class="zuoce-title">今日付款额</div>
            <el-dropdown
              style="z-index: 99999"
              size="mini"
              split-button
              type="primary"
              @command="handleCommand"
              class="caidan"
            >
              {{ yue }}
              <el-dropdown-menu slot="dropdown" style="z-index: 99999">
                <el-dropdown-item :command="item3"   v-for="(item3, index3) in fukuan" :key="index3">{{item3}}</el-dropdown-item>
                <!-- <el-dropdown-item command="2022-02">2022-02</el-dropdown-item>
                <el-dropdown-item command="2022-03">2022-03</el-dropdown-item>
                <el-dropdown-item command="2022-04">2022-04</el-dropdown-item>
                <el-dropdown-item command="2022-05">2022-05</el-dropdown-item>
                <el-dropdown-item command="2022-06">2022-06</el-dropdown-item>
                <el-dropdown-item command="2022-07">2022-07</el-dropdown-item>
                <el-dropdown-item command="2022-08">2022-08</el-dropdown-item>
                <el-dropdown-item command="2022-09">2022-09</el-dropdown-item>
                <el-dropdown-item command="2022-10">2022-10</el-dropdown-item>
                <el-dropdown-item command="2022-11">2022-11</el-dropdown-item>
                <el-dropdown-item command="2022-12">2022-12</el-dropdown-item> -->
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div class="zuoce-img-dot">
            <img src="../assets/tiao.png" alt="" />
          </div>
          <div class="dapsz">
            <span class="num-box">￥</span>
            <span
              class="num-box Uni-Sans-Regular"
              v-for="(item, index) in numArr"
              :key="index"
              >{{ item }}</span>
            <img
              src="../assets/shue.png"
              alt=""
              style="position: absolute; bottom: 25%; width: 90%"
            />
            <div class="fukue">
              <div>
                <span style="color: #fff">周付款额</span>
                <span class="lanseq Uni-Sans-Regular" v-if="fuk == ''">￥{{ fuk.week_min_value }}</span>
                <span class="lanseq Uni-Sans-Regular" v-else>￥{{ fuk.week_min_value }}</span>
              </div>
              <div>
                <span style="color: #fff">月付款额</span>
                <span class="lanseq Uni-Sans-Regular" v-if="fuk == ''">￥{{ fuk.month_min_value }}</span>
                <span class="lanseq Uni-Sans-Regular" v-else>￥{{ fuk.month_min_value }}</span>
              </div>
            </div>

          </div>

        </div>
        <div class="zuocet2">
          <div
            style="
              display: flex;
              justify-content: space-between;
              width: 95%;
              margin-left: 2.5%;
            "
          >
            <div class="zuoce-title">企业信息</div>
            <span style="font-size: 0.5vw; color: #fff"
              >截止时间至{{ xinxi.now_time }} </span
            >
          </div>
          <div class="zuoce-img-dot">
            <img src="../assets/tiao.png" alt="" />
          </div>
          <div class="qiyex">
            <div class="qyxx">
              <div style="margin-left: 10px; margin-top: 5px">
                <p class="shouruzi">营收金额</p>
                <div
                  style="display: flex; align-items: center; margin-top: 3px"
                >
                  <p class="qyxxqi Uni-Sans-Regular">￥{{ xinxi.tax_money }}</p>
                </div>
              </div>
            </div>
            <div class="qyxx">
              <div id="qiye"></div>
              <div style="margin-left: 10px; margin-top: 5px">
                <p class="shouruzi">合作企业数量</p>
                <div
                  style="display: flex; align-items: center; margin-top: 3px"
                >
                  <p class="qyxxqi Uni-Sans-Regular">
                    {{ xinxi.cooperatives_num }}
                  </p>
                </div>
              </div>
            </div>
            <div class="qyxx">
              <div style="margin-left: 10px; margin-top: 5px">
                <div style="display: flex; align-items: center">
                  <p class="shouruzi">总投资金额</p>
                  <p style="color: #fff; margin-left: 15px" class="hbbh">
                    环比变化 {{ xinxi.investment_money_change }}%
                  </p>
                </div>
                <div
                  style="display: flex; align-items: center; margin-top: 3px"
                >
                  <p class="qyxxqi Uni-Sans-Regular">
                    ￥{{ xinxi.investment_money }}
                  </p>
                </div>
              </div>
            </div>
            <div class="qyxx">
              <div style="margin-left: 10px; margin-top: 5px">
                <div style="display: flex; align-items: center">
                  <p class="shouruzi">营收总金额</p>
                  <p style="color: #fff; margin-left: 15px" class="hbbh">
                    环比变化 {{ xinxi.tax_take_change }}%
                  </p>
                </div>
                <div
                  style="display: flex; align-items: center; margin-top: 3px"
                >
                  <p class="qyxxqi Uni-Sans-Regular">￥{{ xinxi.tax_take }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="zuocet">
          <div
            style="
              display: flex;
              justify-content: space-between;
              width: 95%;
              margin-left: 2.5%;
            "
          >
            <div class="zuoce-title">增值服务</div>
            <span style="font-size: 0.5vw; color: #fff"
              >截止时间至{{ xinxi.now_time }}</span
            >
          </div>
          <div class="zuoce-img-dot">
            <img src="../assets/tiao.png" alt="" />
          </div>
          <div class="dl-data">
            <div class="item">
              <div class="num Uni-Sans-Regular">{{ xinxi.security_check }}</div>
              <div class="label">安全检查</div>
              <div class="icon">
                <img class="sd" src="../assets/bianzu40.png" alt="" />
              </div>
            </div>
            <div class="item">
              <div class="num Uni-Sans-Regular">{{ xinxi.driver_serve }}</div>
              <div class="label">代驾服务</div>
              <div class="icon">
                <img class="sd" src="../assets/bianzu37.png" alt="" />
              </div>
            </div>
            <div class="item">
              <div class="num Uni-Sans-Regular">{{ xinxi.car_pay }}</div>
              <div class="label">扫码挪车</div>
              <div class="icon">
                <img class="sd" src="../assets/bianzu281.png" alt="" />
              </div>
            </div>
            <div class="item">
              <div class="num Uni-Sans-Regular">{{ xinxi.road_rescue }}</div>
              <div class="label">道路救援</div>
              <div class="icon">
                <img class="sd" src="../assets/bianzu401.png" alt="" />
              </div>
            </div>
          </div>
          <div class="dl-data">
            <div class="item">
              <div class="num Uni-Sans-Regular">{{ xinxi.car_clean }}</div>
              <div class="label">车辆清洗</div>
              <div class="icon">
                <img class="sd" src="../assets/bianzu2.png" alt="" />
              </div>
            </div>
            <div class="item">
              <div class="num Uni-Sans-Regular">{{ xinxi.tyre_upkeep }}</div>
              <div class="label">轮胎保养</div>
              <div class="icon">
                <img class="sd" src="../assets/bianzu2beifen.png" alt="" />
              </div>
            </div>
            <div class="item">
              <div class="num Uni-Sans-Regular">{{ xinxi.lacquer_repair }}</div>
              <div class="label">漆面修复</div>
              <div class="icon">
                <img class="sd" src="../assets/bianzu21.png" alt="" />
              </div>
            </div>
            <div class="item">
              <div class="num Uni-Sans-Regular">{{ xinxi.air_repair }}</div>
              <div class="label">空调维修</div>
              <div class="icon">
                <img class="sd" src="../assets/bianzu2beifen3.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="middle">
        <div class="liulan">
          <div class="liulant">
            <div class="jr">今日浏览量/位</div>
            <div class="liulanl Uni-Sans-Regular">
              {{ xinxi.min_look_num }}
            </div>
            <img src="../assets/jianb1.png" alt="" />
          </div>
          <div class="liulant">
            <div class="jr">今日访问量/位</div>
            <div class="liulanl Uni-Sans-Regular">
              {{ xinxi.min_visit_num }}
            </div>
            <img src="../assets/jianb2.png" alt="" />
          </div>
          <div class="liulant">
            <div class="jr">今日成交量/位</div>
            <div class="liulanl Uni-Sans-Regular">
              {{ xinxi.min_make_num }}
            </div>
            <img src="../assets/jianb3.png" alt="" />
          </div>
        </div>
        <div class="zl">
          <div class="ti">
            <img src="../assets/hd407.png" alt="" />
            <img src="../assets/hd403.png" alt="" />
            <img src="../assets/hd404.png" alt="" />
            <img src="../assets/hd405.png" alt="" />
          </div>
          <div class="tit">
            <img src="../assets/tit.png" alt="" />
            <img src="../assets/tit.png" alt="" />
            <img src="../assets/tit.png" alt="" />
            <img src="../assets/tit.png" alt="" />
          </div>
          <div class="ttzi">
            <div class="yg">
              <span class="yg1">高端技术人员</span>
              <span class="yg2 Uni-Sans-Regular">{{
                xinxi.technology_member
              }}</span>
              <span class="yg3">相关员工</span>
            </div>
            <div class="yg">
              <span class="yg1">智慧办公</span>
              <span class="yg2 Uni-Sans-Regular">{{ xinxi.smart_office }}</span>
              <span class="yg3">区域面积/m²</span>
            </div>
            <div class="yg">
              <span class="yg1">研发技术</span>
              <span class="yg2 Uni-Sans-Regular">{{
                xinxi.patented_technology
              }}</span>
              <span class="yg3">研发总数</span>
            </div>
            <div class="yg">
              <span class="yg1">数据报表</span>
              <span class="yg2 Uni-Sans-Regular">{{ xinxi.data_report }}</span>
              <span class="yg3">报表下载总数</span>
            </div>
          </div>
        </div>
        <div class="zhu">
          <div
            style="
              display: flex;
              justify-content: space-between;
              width: 95%;
              margin-left: 2.5%;
              top: 5%;
              position: absolute;
            "
          >
            <div style="display: flex; align-items: center">
              <div class="zuoce-title">月度营收概览</div>
              <div class="danwei">单位/万元</div>
            </div>
            <el-dropdown
              size="mini"
              split-button
              type="primary"
              @command="handleCommandSs"
              class="caidan"
            >
              {{ yueSs }}
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="2024">2024</el-dropdown-item>
                <el-dropdown-item command="2023">2023</el-dropdown-item>
                <el-dropdown-item command="2022">2022</el-dropdown-item>
                <el-dropdown-item command="2021">2021</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div id="zhut"></div>
        </div>
      </div>
      <div class="meige">
        <div class="zuocet1">
          <div
            style="
              display: flex;
              justify-content: space-between;
              width: 90%;
              margin-left: 5%;
            "
          >
            <div class="zuoce-title">营业额</div>
            <el-dropdown
              size="mini"
              split-button
              type="primary"
              @command="handleCommandYy"
              class="caidan"
            >
              {{ yueYz }}
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="1">第一季度</el-dropdown-item>
                <el-dropdown-item command="2">第二季度</el-dropdown-item>
                <el-dropdown-item command="3">第三季度</el-dropdown-item>
                <el-dropdown-item command="4">第四季度</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div class="youtu">
            <img class="" src="../assets/tiao.png" alt="" />
          </div>
          <div class="yingyex">
            <div id="chart_33"></div>
            <div class="yzt">
              <div class="yuan">
                <p class="baifen">{{ YingYe.one_value }}%</p>
                <img class="zhui" src="../assets/yuanzhui.png" alt="" />
                <p class="shoulv">第一个月营收占比</p>
              </div>
              <div class="yuan">
                <p class="baifen">{{ YingYe.two_value }}%</p>

                <img class="zhui" src="../assets/yuanzhui.png" alt="" />
                <p class="shoulv">第二个月营收占比</p>
              </div>
              <div class="yuan">
                <p class="baifen">{{ YingYe.three_value }}%</p>

                <img class="zhui" src="../assets/yuanzhui.png" alt="" />
                <p class="shoulv">第三个月营收占比</p>
              </div>
            </div>
          </div>
        </div>
        <div class="zuocet2">
          <div
            style="
              display: flex;
              justify-content: space-between;
              width: 90%;
              margin-left: 5%;
            "
          >
            <div class="zuoce-title">收入来源</div>
            <span style="font-size: 0.5vw; color: #fff; margin-top: 5px"
              >截止时间至{{ xinxi.now_time }}</span
            >
          </div>
          <div class="youtu">
            <img class="" src="../assets/tiao.png" alt="" />
          </div>
          <div class="shouru">
            <div class="srtu">
              <div id="zhanbi"></div>
              <div style="margin-left: 5px">
                <p class="shouruzi">中国人保</p>
                <div
                  style="display: flex; align-items: center; margin-top: 3px"
                >
                  <p class="shouruqi Uni-Sans-Regular">
                    ￥{{ xinxi.insur_tech }}
                  </p>
                  <span
                    style="font-size: 0.6vw; color: #5be5ed; margin-left: 5px"
                  >
                    万元</span
                  >
                </div>
              </div>
            </div>
            <div class="srtu">
              <div id="yaolian"></div>
              <div style="margin-left: 5px">
                <p class="shouruzi">中国人寿</p>
                <div
                  style="display: flex; align-items: center; margin-top: 3px"
                >
                  <p class="shouruqi Uni-Sans-Regular">
                    ￥{{ xinxi.medicine_health }}
                  </p>
                  <span
                    style="font-size: 0.6vw; color: #5be5ed; margin-left: 5px"
                  >
                  万元</span
                  >
                </div>
              </div>
            </div>
            <div class="srtu">
              <div id="qiche"></div>
              <div style="margin-left: 5px">
                <p class="shouruzi">中国平安</p>
                <div
                  style="display: flex; align-items: center; margin-top: 3px"
                >
                  <p class="shouruqi Uni-Sans-Regular">
                    ￥{{ xinxi.car_insurance }}
                  </p>
                  <span
                    style="font-size: 0.6vw; color: #5be5ed; margin-left: 5px"
                  >
                  万元</span
                  >
                </div>
              </div>
            </div>
            <div class="srtu">
              <div id="guangg"></div>
              <div style="margin-left: 5px">
                <p class="shouruzi">其他业务</p>
                <div
                  style="display: flex; align-items: center; margin-top: 3px"
                >
                  <p class="shouruqi Uni-Sans-Regular">
                    ￥{{ xinxi.ad_media }}
                  </p>
                  <span
                    style="font-size: 0.6vw; color: #5be5ed; margin-left: 5px"
                  >
                  万元</span
                  >
                </div>
              </div>
            </div>

          </div>
        </div>

        <div class="zuocet">
          <div
            style="
              display: flex;
              justify-content: space-between;
              width: 90%;
        margin-left: 5%;
            "
          >
            <div class="zuoce-title">未来规划</div>
            <span style="font-size: 0.5vw; color: #fff; margin-top: 5px"
              >截止时间至{{ xinxi.now_time }}</span
            >
          </div>
          <div class="youtu">
            <img class="" src="../assets/tiao.png" alt="" />
          </div>

          <div class="bingtu">
            <div class="tu">
              <div class="topzi">
                <span class="topzil">环比变化</span
                ><span class="topzir"> +{{ xinxi.member_num_change }}%</span>
              </div>
              <div class="middlezi">
                <span class="Uni-Sans-Regular">{{ xinxi.member_num }}</span
                ><span>万</span>
              </div>
              <div class="dibuzi">用户数量</div>
            </div>
            <div class="tu">
              <div class="topzi">
                <span class="topzil">环比变化</span
                ><span class="topzir"> +{{ xinxi.gross_revenue_change }}%</span>
              </div>
              <div class="middlezi">
                <span class="Uni-Sans-Regular">{{ xinxi.gross_revenue }}</span
                ><span>万</span>
              </div>
              <div class="dibuzi">对外营收总额</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

// import countTo from 'vue-count-to';
export default {
  // components: { countTo },
  name: "MenuName",
  data() {
    return {
      yue: "",
      xinxi: {
        banner:[
          {"image":"https://qmdata.iueang.com//uploads/20230315/2008e9bae751332c92a9f6b9d7919ff6.jpg"},
          {"image":"https://qmdata.iueang.com//uploads/20230315/2008e9bae751332c92a9f6b9d7919ff6.jpg"},
          {"image":"https://qmdata.iueang.com//uploads/20230315/2008e9bae751332c92a9f6b9d7919ff6.jpg"},
          {"image":"https://qmdata.iueang.com//uploads/20230315/2008e9bae751332c92a9f6b9d7919ff6.jpg"},
        ]
      },
      fukuan:[],
      YingYe: {},
      yueYy: 0,
      yueYz: "",
      yueSs: "2024",
      duration: 5000,
      // zhoufu:'10,910,000',
      // yuefu:'10,910,000',
      fuk:'',
      Ss: [],
      timer:'',
      timer2:'',
      timer3:'',
      timer4:'',
      timer5:'',
      timer6:'',
      // 初始值
      startVal: 0,
      // 最终值
      endVal: 83088888,
      numArr: [],
    };
  },
  created() {
    document.title = "青民数科大屏";
  },
  mounted() {
    this.getdata();
    this.getTime();
    this.getZhu();
    this.getYy();
    this.getlist()
  },
  beforeDestroy() {
    clearInterval(this.timer);
    clearInterval(this.timer2);
    clearInterval(this.timer3);
    clearInterval(this.timer4);
    clearInterval(this.timer5);
    clearInterval(this.timer6);
  },
  methods: {
    getlist(){
      this.$axios
        .post("/api/index/dateList", {
        })
        .then((res) => {
          this.fukuan=res.data.data

          var default_yue = this.fukuan.length -1;
          console.log(default_yue)

          this.yue = this.fukuan[default_yue]
        })
        .catch((error) => console.log(error));
    },
    getYy() {
      this.$axios
        .post("/api/index/turnover", {
          time: this.yueYy,
        })
        .then((res) => {
          this.YingYe = res.data.data;
          this.list1();
        })
        .catch((error) => console.log(error));
    },
    getZhu() {
      this.$axios
        .post("/api/index/year", {
          time: this.yueSs,
        })
        .then((res) => {
          this.Ss = res.data.data;
          this.list2();
        })
        .catch((error) => console.log(error));
    },
    getTime() {
      this.$axios
        .post("/api/index/today", {
          time: this.yue,
        })
        .then((res) => {
          this.fuk = res.data.data;
          this.numArr =  res.data.data.min_value.split("");
          this.timer = setInterval(this.DayChange, this.fuk.interval_value * 1000);
          this.timer2 = setInterval(this.WeekChange, this.fuk.week_interval_value * 1000);
          this.time3 = setInterval(this.MounthChange, this.fuk.month_interval_value * 1000);
        })
        .catch((error) => console.log(error));
    },
    overDay(){
      clearInterval(this.timer);
    },
    overWeek(){
      clearInterval(this.timer2);
    },
    overMounth(){
      clearInterval(this.timer3);
    },
    DayChange(){
      this.$axios
          .post("/api/index/today", {
            time: this.yue,
          })
          .then((res) => {
            console.log(res,'定时数据')
            this.fuk.min_value= res.data.data.min_value;
            this.numArr =  String(this.fuk.min_value).split("");
          })
          .catch((error) => console.log(error));

      // this.fuk.min_value=Number(this.fuk.min_value)+Number(this.fuk.increase_value);
      // this.fuk.min_value= parseFloat(this.fuk.min_value).toFixed(2)
      // this.numArr =  String(this.fuk.min_value).split("");
      // if(this.fuk.min_value>=this.fuk.max_value){
      //   this.overDay()
      // }
    },
    WeekChange(){
      this.fuk.week_min_value=Number(this.fuk.week_min_value)+Number(this.fuk.week_increase_value)
      this.fuk.week_min_value= parseFloat(this.fuk.week_min_value).toFixed(2)
      if(this.fuk.week_min_value>=this.fuk.week_max_value){
        this.overWeek()
      }
    },
    MounthChange(){
      this.fuk.month_min_value=Number(this.fuk.month_min_value)+Number(this.fuk.month_increase_value)
      this.fuk.month_min_value= parseFloat(this.fuk.month_min_value).toFixed(2)
      if(this.fuk.month_min_value>=this.fuk.month_max_value){
        this.overMounth()
      }
    },
    LeftChange(){
      this.xinxi.min_look_num=Number(this.xinxi.min_look_num)+Number(this.xinxi.add_look_num)
      if(this.xinxi.min_look_num>= Number(this.xinxi.max_look_num)){
        this.overLeft()
      }
    },
    MidChange(){
      this.xinxi.min_visit_num=Number(this.xinxi.min_visit_num)+Number(this.xinxi.add_visit_num)
      if(this.xinxi.min_visit_num >= Number(this.xinxi.max_visit_num)){
        this.overMid()
      }
    },
    RightChange(){
      this.xinxi.min_make_num=Number(this.xinxi.min_make_num)+Number(this.xinxi.add_make_num)
      if(this.xinxi.min_make_num>=Number(this.xinxi.max_make_num)){
        this.overRight()
      }
    },
    overLeft(){
      clearInterval(this.timer4);
    },
    overMid(){
      clearInterval(this.timer5);
    },
    overRight(){
      clearInterval(this.timer6);
    },
    getdata() {
      this.$axios
        .post("/api/index/index")
        .then((res) => {
          this.xinxi = res.data.data;
          this.yueYy= this.xinxi.yueYy;
          this.yueYz= this.xinxi.yueYz;
          this.timer4 = setInterval(this.LeftChange, this.xinxi.interval_look_num * 1000);
          this.timer5 = setInterval(this.MidChange, this.xinxi.interval_visit_num * 1000);
          this.timer6 = setInterval(this.RightChange, this.xinxi.interval_make_num * 1000);
          // var timeOut = setTimeout(() => {
          //   this.getdata();
          // }, 5000);
          this.list4();
          this.list6();
          this.list7();
          this.list8();
          this.list9();
        })
        .catch((error) => console.log(error));
    },
    handleCommand(command) {
      this.yue = command;
      console.log(command);
      this.getTime();
    },
    handleCommandYy(command) {
      this.yueYy = command;
      this.getYy();

      if (command == 1) {
        this.yueYz = "第一季度";
      } else if (command == 2) {
        this.yueYz = "第二季度";
      } else if (command == 3) {
        this.yueYz = "第三季度";
      } else if (command == 4) {
        this.yueYz = "第四季度";
      }
    },
    handleCommandSs(command) {
      // this.$message('click on item ' + command);
      this.yueSs = command;
      this.getZhu();
    },

    list9() {
      let echarts = require("echarts");
      let myChart9 = echarts.init(document.getElementById("qiye"));

      let option9 = {
        // title: {
        //     text: '作业管理',
        //     textStyle: {
        //         color: '#fff',
        //         fontSize:".3vw",
        //     }
        // },
        // backgroundColor: '#090d1a',
        legend: {
          orient: "vertical",
          show: true,
          right: "10%",
          y: "center",
          itemWidth: 3,
          itemHeight: 30,
          itemGap: 20,
          textStyle: {
            color: "#7a8c9f",
            fontSize: 14,
            lineHeight: 20,
            rich: {
              percent: {
                color: "#fff",
                fontSize: ".3vw",
              },
            },
          },
          formatter: (name) => {
            switch (name) {
              case "运行中":
                return "运行中\r\n{percent|30%} " + " " + 30;
              case "已停止":
                return "已停止\r\n{percent|30%} " + " " + 30;
              case "未上线":
                return "未上线\r\n{percent|40%} " + " " + 40;
              default:
                break;
            }
          },
        },
        tooltip: {
          show: true,
        },
        series: [
          {
            type: "pie",
            radius: ["65%", "75%"],
            center: ["40%", "50%"],
            hoverAnimation: false,
            z: 10,
            label: {
              position: "center",
              formatter: () => {
                return "作业总数\r\n{total|100} 个";
              },
              rich: {
                total: {
                  fontSize: ".3vw",
                  color: "#fff",
                },
              },
              color: "#7a8c9f",
              fontSize: ".3vw",
              lineHeight: ".3vw",
            },
            data: [
              {
                value: this.xinxi.cooperatives_num,
                name: "",
                itemStyle: {
                  color: "#5EF9F8",
                },
              },
              {
                value: this.xinxi.state_enterprise,
                name: "",
                itemStyle: {
                  color: "#DDA712",
                },
              },
            ],
            labelLine: {
              show: false,
            },
          },
          {
            type: "pie",
            radius: ["54%", "64%"],
            center: ["40%", "50%"],
            hoverAnimation: false,
            label: {
              show: false,
            },
            data: [
              {
                value: this.xinxi.cooperatives_num,
                name: "",
                itemStyle: {
                  color: "#5EF9F8",
                  opacity: 0.8,
                },
              },
              {
                value: this.xinxi.state_enterprise,
                name: "",
                itemStyle: {
                  color: "#DDA712",
                  opacity: 0.8,
                },
              },
            ],
            labelLine: {
              show: false,
            },
          },
          {
            type: "pie",
            radius: ["43%", "53%"],
            center: ["40%", "50%"],
            hoverAnimation: false,
            label: {
              show: false,
            },
            data: [
              {
                value: 30,
                name: "",
                itemStyle: {
                  color: "#0286ff",
                  opacity: 0.1,
                },
              },
              {
                value: 30,
                name: "",
                itemStyle: {
                  color: "#ffd302",
                  opacity: 0.1,
                },
              },
            ],
            labelLine: {
              show: false,
            },
          },
        ],
      };

      myChart9.setOption(option9, true);
    },
    list6() {
      let echarts = require("echarts");
      let myChart6 = echarts.init(document.getElementById("qiche"));
      let option6 = {
        title: {
          text: this.xinxi.car_insurance_percent + "%",
          x: "center",
          y: "center",
          textStyle: {
            fontWeight: "normal",
            color: "#fff",
            fontSize: "1vw",
          },
        },
        color: "#000",
        series: [
          {
            name: "Line 1",
            type: "pie",
            clockWise: true,
            radius: ["50%", "66%"],
            itemStyle: {
              normal: {
                label: {
                  show: false,
                },
                labelLine: {
                  show: false,
                },
              },
            },
            hoverAnimation: false,
            data: [
              {
                value: this.xinxi.car_insurance_percent,
                name: "01",
                itemStyle: {
                  normal: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      {
                        offset: 0,
                        color: "#F5B500",
                      },
                      {
                        offset: 1,
                        color: "#6DD400",
                      },
                    ]),
                    label: {
                      show: false,
                    },
                    labelLine: {
                      show: false,
                    },
                  },
                },
              },
              {
                name: "02",
                value: 20,
              },
            ],
          },
        ],
      };

      myChart6.setOption(option6, true);
    },
    list7() {
      let echarts = require("echarts");
      let myChart7 = echarts.init(document.getElementById("guangg"));
      let option7 = {
        title: {
          text: this.xinxi.ad_media_percent + "%",
          x: "center",
          y: "center",
          textStyle: {
            fontWeight: "normal",
            color: "#fff",
            fontSize: "1vw",
          },
        },
        color: "#000",
        series: [
          {
            name: "Line 1",
            type: "pie",
            clockWise: true,
            radius: ["50%", "66%"],
            itemStyle: {
              normal: {
                label: {
                  show: false,
                },
                labelLine: {
                  show: false,
                },
              },
            },
            hoverAnimation: false,
            data: [
              {
                value: this.xinxi.ad_media_percent,
                name: "01",
                itemStyle: {
                  normal: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      {
                        offset: 0,
                        color: "#2778E2",
                      },
                      {
                        offset: 1,
                        color: "#2377DD",
                      },
                    ]),
                    label: {
                      show: false,
                    },
                    labelLine: {
                      show: false,
                    },
                  },
                },
              },
              {
                name: "02",
                value: 20,
              },
            ],
          },
        ],
      };

      myChart7.setOption(option7, true);
    },
    list8() {
      let echarts = require("echarts");
      let myChart8 = echarts.init(document.getElementById("yaolian"));
      let option8 = {
        title: {
          text: this.xinxi.medicine_health_percent + "%",
          x: "center",
          y: "center",
          textStyle: {
            fontWeight: "normal",
            color: "#fff",
            fontSize: "1vw",
          },
        },
        color: "#000",
        series: [
          {
            name: "Line 1",
            type: "pie",
            clockWise: true,
            radius: ["50%", "66%"],
            itemStyle: {
              normal: {
                label: {
                  show: false,
                },
                labelLine: {
                  show: false,
                },
              },
            },
            hoverAnimation: false,
            data: [
              {
                value: this.xinxi.medicine_health_percent,
                name: "01",
                itemStyle: {
                  normal: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      {
                        offset: 0,
                        color: "#1F98C9",
                      },
                      {
                        offset: 1,
                        color: "#5BEFF6",
                      },
                    ]),
                    label: {
                      show: false,
                    },
                    labelLine: {
                      show: false,
                    },
                  },
                },
              },
              {
                name: "02",
                value: 20,
              },
            ],
          },
        ],
      };

      myChart8.setOption(option8, true);
    },
    list4() {
      let echarts = require("echarts");
      let myChart4 = echarts.init(document.getElementById("zhanbi"));
      let option4 = {
        title: {
          text: this.xinxi.insur_tech_percent + "%",
          x: "center",
          y: "center",
          textStyle: {
            fontWeight: "normal",
            color: "#fff",
            fontSize: "1vw",
          },
        },
        color: "#000",
        series: [
          {
            name: "Line 1",
            type: "pie",
            clockWise: true,
            radius: ["50%", "66%"],
            itemStyle: {
              normal: {
                label: {
                  show: false,
                },
                labelLine: {
                  show: false,
                },
              },
            },
            hoverAnimation: false,
            data: [
              {
                value: this.xinxi.insur_tech_percent,
                name: "01",
                itemStyle: {
                  normal: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      {
                        offset: 0,
                        color: "#49CB62",
                      },
                      {
                        offset: 1,
                        color: "#88DB52",
                      },
                    ]),
                    label: {
                      show: false,
                    },
                    labelLine: {
                      show: false,
                    },
                  },
                },
              },
              {
                name: "02",
                value: 20,
              },
            ],
          },
        ],
      };

      myChart4.setOption(option4, true);
    },
    list2() {
      // let that=this
      let echarts = require("echarts");
      let myChart2 = echarts.init(document.getElementById("zhut"));

      let option2 = {
        tooltip: {
          // trigger: 'axis',
          axisPointer: {
            // type: 'shadow',
          },
        },
        legend: {
          top: "3%",
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        yAxis: {
          axisLabel: {
            textStyle: {
              color: "#ffffff",
            },
          },
          type: "value",
          splitLine: {
            //分割线配置
            show: true,
            lineStyle: {
              opacity: 0.4,
            },
          },
          boundaryGap: [0, 0],
        },

        xAxis: {
          axisLabel: {
            textStyle: {
              color: "#ffffff",
            },
          },
          type: "category",
          data: [
            "1月",
            "2月",
            "3月", "4月", "5月", "6月", "7月", "8月", "9月", "10月", "11月", "12月",
          ],
        },
        series: [
          {
            name: "",
            type: "bar",
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: "#00CAD4",
                  },
                  {
                    offset: 1,
                    color: "#00A5FC",
                  },
                ]),
                label: {
                  show: true, //开启显示
                  position: 'top', //在上方显示
                  formatter:function(val){
                    if(val.value == 0){
                      return ""
                    }
                  },
                  textStyle: { //数值样式
                    color: 'white',
                    fontSize: 12
                  }
                },
                barBorderRadius: 1,
              },

            },
            label: {
              show: true,
              position: "top",
              color: "#fff", // 文字颜色
            },
            barWidth: 25,
            data: this.Ss,
          },
        ],
      };

      myChart2.setOption(option2, true);
    },
    list1() {
      if (document.getElementById("chart_33")) {
        document
          .getElementById("chart_33")
          .removeAttribute("_echarts_instance_");
      }
      let echarts = require("echarts");

      let myChart = echarts.init(document.getElementById("chart_33"));
      let angle = 0; //角度，用来做简单的动画效果的
      let value = this.YingYe.value;
      var timerId;
      let option = {
        //  backgroundColor: '#000E1A',
        title: {
          text: "{a|" + value + "}{c|%}",
          x: "center",
          y: "center",
          textStyle: {
            rich: {
              a: {
                //  fontSize: ,
                color: "#29EEF3",
              },

              c: {
                //  fontSize: 20,
                color: "#ffffff",
                // padding: [5,0]
              },
            },
          },
        },

        series: [
          // 紫色
          {
            name: "ring5",
            type: "custom",
            coordinateSystem: "none",
            renderItem: function (params, api) {
              return {
                type: "arc",
                shape: {
                  cx: api.getWidth() / 2,
                  cy: api.getHeight() / 2,
                  r: (Math.min(api.getWidth(), api.getHeight()) / 2) * 0.6,
                  startAngle: ((0 + angle) * Math.PI) / 180,
                  endAngle: ((90 + angle) * Math.PI) / 180,
                },
                style: {
                  stroke: "#8383FA",
                  fill: "transparent",
                  lineWidth: 1.5,
                },
                silent: true,
              };
            },
            data: [0],
          },
          {
            name: "ring5", //紫点
            type: "custom",
            coordinateSystem: "none",
            renderItem: function (params, api) {
              let x0 = api.getWidth() / 2;
              let y0 = api.getHeight() / 2;
              let r = (Math.min(api.getWidth(), api.getHeight()) / 2) * 0.6;
              let point = getCirlPoint(x0, y0, r, 90 + angle);
              return {
                type: "circle",
                shape: {
                  cx: point.x,
                  cy: point.y,
                  r: 4,
                },
                style: {
                  stroke: "#8450F9", //绿
                  fill: "#8450F9",
                },
                silent: true,
              };
            },
            data: [0],
          },
          // 蓝色

          {
            name: "ring5",
            type: "custom",
            coordinateSystem: "none",
            renderItem: function (params, api) {
              return {
                type: "arc",
                shape: {
                  cx: api.getWidth() / 2,
                  cy: api.getHeight() / 2,
                  r: (Math.min(api.getWidth(), api.getHeight()) / 2) * 0.6,
                  startAngle: ((180 + angle) * Math.PI) / 180,
                  endAngle: ((270 + angle) * Math.PI) / 180,
                },
                style: {
                  stroke: "#4386FA",
                  fill: "transparent",
                  lineWidth: 1.5,
                },
                silent: true,
              };
            },
            data: [0],
          },
          {
            name: "ring5", // 蓝色
            type: "custom",
            coordinateSystem: "none",
            renderItem: function (params, api) {
              let x0 = api.getWidth() / 2;
              let y0 = api.getHeight() / 2;
              let r = (Math.min(api.getWidth(), api.getHeight()) / 2) * 0.6;
              let point = getCirlPoint(x0, y0, r, 180 + angle);
              return {
                type: "circle",
                shape: {
                  cx: point.x,
                  cy: point.y,
                  r: 4,
                },
                style: {
                  stroke: "#4386FA", //绿
                  fill: "#4386FA",
                },
                silent: true,
              };
            },
            data: [0],
          },

          {
            name: "ring5",
            type: "custom",
            coordinateSystem: "none",
            renderItem: function (params, api) {
              return {
                type: "arc",
                shape: {
                  cx: api.getWidth() / 2,
                  cy: api.getHeight() / 2,
                  r: (Math.min(api.getWidth(), api.getHeight()) / 2) * 0.65,
                  startAngle: ((270 + -angle) * Math.PI) / 180,
                  endAngle: ((40 + -angle) * Math.PI) / 180,
                },
                style: {
                  stroke: "#0CD3DB",
                  fill: "transparent",
                  lineWidth: 1.5,
                },
                silent: true,
              };
            },
            data: [0],
          },
          // 橘色

          {
            name: "ring5",
            type: "custom",
            coordinateSystem: "none",
            renderItem: function (params, api) {
              return {
                type: "arc",
                shape: {
                  cx: api.getWidth() / 2,
                  cy: api.getHeight() / 2,
                  r: (Math.min(api.getWidth(), api.getHeight()) / 2) * 0.65,
                  startAngle: ((90 + -angle) * Math.PI) / 180,
                  endAngle: ((220 + -angle) * Math.PI) / 180,
                },
                style: {
                  stroke: "#49CB62",
                  fill: "transparent",
                  lineWidth: 1.5,
                },
                silent: true,
              };
            },
            data: [0],
          },
          {
            name: "ring5",
            type: "custom",
            coordinateSystem: "none",
            renderItem: function (params, api) {
              let x0 = api.getWidth() / 2;
              let y0 = api.getHeight() / 2;
              let r = (Math.min(api.getWidth(), api.getHeight()) / 2) * 0.65;
              let point = getCirlPoint(x0, y0, r, 90 + -angle);
              return {
                type: "circle",
                shape: {
                  cx: point.x,
                  cy: point.y,
                  r: 4,
                },
                style: {
                  stroke: "#49CB62", //粉
                  fill: "#49CB62",
                },
                silent: true,
              };
            },
            data: [0],
          },
          {
            name: "ring5", //绿点
            type: "custom",
            coordinateSystem: "none",
            renderItem: function (params, api) {
              let x0 = api.getWidth() / 2;
              let y0 = api.getHeight() / 2;
              let r = (Math.min(api.getWidth(), api.getHeight()) / 2) * 0.65;
              let point = getCirlPoint(x0, y0, r, 270 + -angle);
              return {
                type: "circle",
                shape: {
                  cx: point.x,
                  cy: point.y,
                  r: 4,
                },
                style: {
                  stroke: "#0CD3DB", //绿
                  fill: "#0CD3DB",
                },
                silent: true,
              };
            },
            data: [0],
          },
          {
            name: "吃猪肉频率",
            type: "pie",
            radius: ["52%", "40%"],
            silent: true,
            clockwise: true,
            startAngle: 90,
            z: 0,
            zlevel: 0,
            label: {
              normal: {
                position: "center",
              },
            },
            data: [
              {
                value: value,
                name: "",
                itemStyle: {
                  normal: {
                    color: {
                      // 完成的圆环的颜色
                      colorStops: [
                        {
                          offset: 0,
                          color: "#00CAD4", // 0% 处的颜色
                        },
                        {
                          offset: 1,
                          color: "#00A5FC", // 100% 处的颜色
                        },
                      ],
                    },
                  },
                },
              },
              {
                value: 100 - value,
                name: "",
                label: {
                  normal: {
                    show: false,
                  },
                },
                itemStyle: {
                  normal: {
                    color: "#173164",
                  },
                },
              },
            ],
          },
          {
            name: "吃猪肉频率",
            type: "pie",
            radius: ["32%", "35%"],
            silent: true,
            clockwise: true,
            startAngle: 270,
            z: 0,
            zlevel: 0,
            label: {
              normal: {
                position: "center",
              },
            },
            data: [
              {
                value: value,
                name: "",
                itemStyle: {
                  normal: {
                    color: {
                      // 完成的圆环的颜色
                      colorStops: [
                        {
                          offset: 0,
                          color: "#00EDF3", // 0% 处的颜色
                        },
                        {
                          offset: 1,
                          color: "#646CF9", // 100% 处的颜色
                        },
                      ],
                    },
                  },
                },
              },
              {
                value: 100 - value,
                name: "",
                label: {
                  normal: {
                    show: false,
                  },
                },
                itemStyle: {
                  normal: {
                    color: "#173164",
                  },
                },
              },
            ],
          },
        ],
      };

      //获取圆上面某点的坐标(x0,y0表示坐标，r半径，angle角度)
      function getCirlPoint(x0, y0, r, angle) {
        let x1 = x0 + r * Math.cos((angle * Math.PI) / 180);
        let y1 = y0 + r * Math.sin((angle * Math.PI) / 180);
        return {
          x: x1,
          y: y1,
        };
      }

      function draw() {
        angle = angle + 3;
        myChart.setOption(option, true);
        //window.requestAnimationFrame(draw);
      }
      if (timerId) {
        clearInterval(timerId);
      }
      timerId = setInterval(function () {
        //用setInterval做动画感觉有问题
        draw();
      }, 100);
    },
  },
};
</script>
<style lang="scss">
.shouru {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 90%;
  margin-left: 5%;
  height: 85%;
  margin-top: 5%;
}

.srtu {
  width: 45%;
  height: 45%;
  position: relative;
  background: url(../assets/shouru.png) no-repeat;
  display: flex;
  align-items: center;
}

.shouruzi {
  color: #fff;
  font-size: 0.8vw;
}

.shouruqi {
  color: #5be5ed;
  font-size: 1vw;
}

.qiyex {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 95%;
  margin-left: 2.5%;
  height: 85%;
  margin-top: 10px;
}

.qyxx {
  width: 48%;
  height: 47%;
  position: relative;
  background: url(../assets/2.png) no-repeat;
  display: flex;
  background-size: 100% 100%;
  // border: 2px solid #0E2876;
}

.qyxxzi {
  color: #fff;
  font-size: 0.8vw;
}

.qyxxqi {
  color: #5be5ed;
  font-size: 1.2vw;
}

/* .shuzi{
  top: 35%;
    position: absolute;
    z-index: 99;
  color: #fff;
} */
.hbbh {
  font-size: 0.5vw;

  margin-top: 0.1vw;
}

.dapsz {
  width: 95%;
  height: 30%;
  font-size: 2.8vw;
  letter-spacing: 0.25vw;
  color: rgb(102, 255, 255);
  display: flex;
  justify-content: center;
  margin-left: 2.5%;

  .num-box {
    display: inline-block;
    width: 2.5vw;
    height: 3.5vw;
    text-align: center;
    background: url(../assets/shuzi.png);
    background-size: 2.5vw 3.5vw;
    margin-right: 2px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.bingtu {
  display: flex;
  width: 100%;
  justify-content: space-between;
  height: 65%;
  margin-top: 30px;
}

.tu {
  width: 100%;
  margin: 0 10px;
  text-align: center;
  height: 100%;
  position: relative;
  background-size: 100% 100% !important;
  background: url(../assets/hd406.png) no-repeat;
}

.dibuzi {
  position: absolute;
  width: 100%;
  text-align: center;
  bottom: 9%;
  font-size: 0.8vw;

  left: 0;
  color: #fff;
}

.topzi {
  position: absolute;
  width: 100%;
  font-size: 0.6vw;
  text-align: center;
  bottom: 68%;
  left: 0;
}

.topzil {
  color: #fff;
}

.topzir {
  color: #fedb65;
}

.middlezi {
  position: absolute;
  width: 100%;
  font-size: 1.4vw;
  text-align: center;
  bottom: 42%;
  left: 0;
  color: #5be5ed;
}

.fukue {
  width: 90%;
  display: flex;
  justify-content: space-between;
  font-size: 0.8vw;
  position: absolute;
  bottom: 14%;
}

.fukue > div {
  display: flex;
  letter-spacing: 0;
}

.shuzit {
  position: absolute;
  top: 50%;
  right: 0px;
  display: flex;
  justify-content: space-around;
  height: 30%;
}

.shuzit img {
  width: 10%;
  height: 100%;
}

.danwei {
  font-size: 0.1vw;
  margin-left: 5px;
  color: #fff;
}

.ti {
  width: 95%;
  height: 40%;
  margin-left: 2.5%;
  margin-top: 5%;
  display: flex;
  justify-content: space-between;
}

.ti > img {
  width: 18%;
  height: 100%;
}

.yg {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.yg > span {
  display: block;
}

.tit {
  width: 95%;
  height: 40%;
  margin-left: 2.5%;
  position: absolute;
  bottom: 3%;
  display: flex;
  justify-content: space-between;
}

.tit > img {
  width: 18%;
  height: 100%;
}

.ttzi {
  width: 95%;
  height: 40%;
  position: absolute;
  text-align: center;
  margin-left: 2.5%;
  bottom: 3%;
  display: flex;
  justify-content: space-between;
}

.ttzi > div {
  width: 18%;
  height: 100%;
}

.jr {
  position: absolute;
  width: 100%;
  text-align: center;
  top: 23%;
  font-size: 1vw;
  color: #fff;
}

#shuzig {
  width: 100%;
  height: 100%;
}

.yg1 {
  color: #66ffff;
  margin-top: 5%;
}

.yg2 {
  color: #66ffff;
  font-size: 27px;
  margin-top: 19%;
}

.yg3 {
  color: #fff;
  font-size: 14px;
  margin-bottom: 10%;
  /* margin-top: 16%; */
}

.zhu {
  width: 100%;
  height: 24.3vh;
  background-color: #071959;
  position: relative;
}

.liulanl {
  width: 100%;
  position: absolute;
  color: #fff;

  text-align: center;
  top: 50%;
  font-size: 2vw;
}

.liulan {
  width: 100%;
  height: 17vh;
  display: flex;
  justify-content: space-between;
  /* background-color: #fff; */
}

.zl {
  width: 100%;
  background-color: #071959;
  position: relative;
  height: 32vh;
}

.yingyex {
  display: flex;
  margin-left: -1.5vw;
  justify-content: space-between;
}

.yinyee {
  color: #fff;
  font-size: 18px;
  z-index: 999999;
  margin-left: 15px;
  margin-top: 10px;
}

.yuan {
  width: 3.2vw;
  text-align: center;
}

.liulant {
  width: 32%;
  position: relative;
  height: 100%;
  /* background-color: #000; */
}

.liulant > img {
  width: 100%;
  height: 100%;
}

.yzt {
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  left: 10vw;
  width: 12vw;
  bottom: 3vh;
  color: #fff;
}

* {
  margin: 0;
  padding: 0;
}

.el-button--primary {
  background-color: #184587;
  border-color: #184587;
}

.zuoce-title {
  font-size: 1vw;
  z-index: 999;
  letter-spacing: -0.01px;
  line-height: 22px;
  color: rgba(255, 255, 255, 1);
  text-align: left;
  vertical-align: top;
}

.zuoce-img-dot {
  margin: 2px 0 5px;
  margin-left: 2.5% !important;
  display: flex;

  img {
    width: 3vw;
    height: 0.4vw;
    padding: 0;
  }
}

.youtu {
  margin: 2px 0 10px;
  margin-left: 5% !important;
  display: flex;
  position: absolute;

  img {
    z-index: 99;
    width: 3vw;
    height: 0.4vw;
    padding: 0;
  }
}

.zuocet {
  width: 100%;
  height: 24.3vh;
  overflow: hidden;
  background-color: #071959;
  position: relative;
  padding: 10px;
}

.zuocet1 {
  width: 100%;
  height: 22.3vh;
  overflow: hidden;
  background-color: #071959;
  position: relative;
  padding: 10px;
}

.zuocet2 {
  width: 100%;
  height: 26.8vh;
  overflow: hidden;
  background-color: #071959;
  position: relative;
  padding: 10px;
}

.sd {
  width: 3.5vw;
  height: 2.5vw;
}

.zt {
  height: 75vh;
  display: flex;
  justify-content: space-between;
  padding: 0 1%;
}

.meige {
  /* background-color: aqua; */

  width: 24%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.zhui {
  width: 2.6vw;
  height: 5.5vh;
}

.middle {
  /* background-color: red; */

  width: 49%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

#chart_33 {
  width: 12vw;
  height: 19vh;
  bottom: 0;
}

#zhut {
  width: 90%;
  height: 97%;
  position: absolute !important;
  bottom: 2%;
  margin-left: 5%;
}

#zhanbi {
  width: 3vw;
  height: 3vw;
  z-index: 9999999;
  position: absolute;
  right: -5%;
  top: -12%;
}

#yaolian {
  width: 3vw;
  height: 3vw;
  // z-index: 9999999;
  position: absolute;
  right: -5%;
  top: -12%;
}

#qiye {
  width: 5vw;
  height: 5vw;
  // z-index: 9999999;
  position: absolute;
  right: -3%;
  top: -10%;
}

#guangg {
  width: 3vw;
  height: 3vw;
  z-index: 9999999;
  position: absolute;
  right: -5%;
  top: -12%;
}

#qiche {
  width: 3vw;
  height: 3vw;
  z-index: 9999999;
  position: absolute;
  right: -5%;
  top: -12%;
}

.baifen {
  color: #5be5ed;
  margin-bottom: 10px;
  font-size: 1vw;
}

body {
  background-color: #161622;
  margin: 0;
  height: 100%;
}

.el-row {
  margin-bottom: 2vh !important;
}

.beijing {
  width: 100%;
  height: 22vh;
}

.beijing img {
  width: 100%;
  height: 100%;
}

.el-row {
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
}

.el-col {
  border-radius: 4px;
}

.bg-purple-dark {
  background: #99a9bf;
}

.bg-purple {
  background: #d3dce6;
}

.bg-purple-light {
  background: #e5e9f2;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}

.shoulv {
  font-size: 0.8vw;
}

.icon {
  margin-top: 10px;
}

.dl-data {
  padding: 0px 10px;
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid #0e5fff;

  &:last-child {
    border-bottom: 0;
  }

  .item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 0.6vw;

    .num {
      font-size: 0.8vw;
    }

    .label {
      font-size: 0.6vw;
    }
  }
}
</style>
