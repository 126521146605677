<template class="page-box">
  <div>
    <el-row>
      <el-col :span="24">
        <div class="beijing">
          <img :src="xinxi.banner[3].image" alt="" />
        </div>
      </el-col>
    </el-row>
    <div class="zt">
      <div class="titleImg">
        <img src="@/assets/zu1.png" alt="" />
        <span>团队介绍</span>
      </div>
      <div class="content">
        <div class="square" v-for="(item, index) in dataList" :key="index">
          <img src="@/assets/left 拷贝 4.png" alt="" />
          <span class="titleText">{{ item.name }} {{ item.label }}</span>
          <img class="manImg" :src="item.image" alt="">
          <p v-html="item.content"></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      dataList: [],
      xinxi: {
        banner:[
          {"image":"https://qmdata.iueang.com//uploads/20230315/2008e9bae751332c92a9f6b9d7919ff6.jpg"},
          {"image":"https://qmdata.iueang.com//uploads/20230315/2008e9bae751332c92a9f6b9d7919ff6.jpg"},
          {"image":"https://qmdata.iueang.com//uploads/20230315/2008e9bae751332c92a9f6b9d7919ff6.jpg"},
          {"image":"https://qmdata.iueang.com//uploads/20230315/2008e9bae751332c92a9f6b9d7919ff6.jpg"},
        ]
      },
    }
  },
  mounted(){
    this.getDataAjax()
    this.getdata()
  },
  methods:{
    getdata() {
      this.$axios
        .post("/api/index/index")
        .then((res) => {
          this.xinxi = res.data.data;
        })
        .catch((error) => console.log(error));
    },
    getDataAjax(){
      this.$axios.post('api/index/team').then(res=>{
        this.dataList = res.data.data
      })
    }
  }
};
</script>

<style lang="scss" scoped>
* {
  color: #fff;
}
.el-row {
  margin-bottom: 0 !important;
  .beijing {
    width: 100%;
    height: 22vh;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
.zt {
  height: 78vh;
  padding: 0 1%;
  background-image: url("@/assets/tuceng6.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  flex-direction: column;
  position: relative;
  width: 100vw;
  justify-content: flex-start;
  .titleImg {
    width: 100%;
    height: 10%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
    }
    span {
      font-size: 38px;
      font-family: 'msyhbd';
      position: relative;
      z-index: 3;
    }
  }
  .content {
    height: 48vh;
    margin-top: 10vh;
    display: flex;
    justify-content: center;
    // background-color: red;
    .square {
      width: 18%;
      height: 100%;
      margin: 0 1%;
      position: relative;
      padding: 2% 2% 0;
      line-height: 1.5;
      img {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        position: absolute;
      }
      .titleText {
        position: absolute;
        left: 5%;
        top: -1%;
        font-size: 18px;
        font-weight: bold;
        color: #58e8ff;
      }
      .manImg{
        position: relative;
        width: 100%;
        height: 40%;
        margin-bottom: 5%;
      }
    }
  }
}
</style>
