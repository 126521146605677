import { render, staticRenderFns } from "./FourScreen.vue?vue&type=template&id=edbfdb1e&scoped=true&class=page-box"
import script from "./FourScreen.vue?vue&type=script&lang=js"
export * from "./FourScreen.vue?vue&type=script&lang=js"
import style0 from "./FourScreen.vue?vue&type=style&index=0&id=edbfdb1e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "edbfdb1e",
  null
  
)

export default component.exports